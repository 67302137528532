.mypackage_margin {
  margin-left: 180px;
  margin-right: 14px;
  padding: 25px 60px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid lightgray;
  overflow-x: hidden;
}

@media (max-width: 992px) {
  .mypackage_margin {
    margin-left: 14px;
    margin-right: 14px;
    padding: 25px 60px;
  }
}

.viewpackagecard-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1em;
  background-color: #f9f9f9;
  transition: transform 0.2s;
  width: 100%;
}

.viewpackagecard-card:hover {
  transform: scale(1.02);
}

.viewpackagecard-name {
  font-size: 1.4em;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.viewpackagecard-destination,
.viewpackagecard-besttime,
.viewpackagecard-travelby,
.viewpackagecard-categories,
.viewpackagecard-day,
.viewpackagecard-price {
  margin: 6px 0;
  color: #555;
  font-size: 0.9em;
}

.viewpackagecard-heading {
  font-weight: bold;
  color: #09646d;
  margin-right: 4px;
  font-size: 1em;
  display: inline-block;
}

.viewpackagecard-destination span,
.viewpackagecard-besttime span,
.viewpackagecard-travelby span,
.viewpackagecard-categories span,
.viewpackagecard-day span,
.viewpackagecard-price span {
  font-size: 0.95em;
  /* color: #000; */
}

.vendor_addpackage_button {
  font-size: 15px;
  background-color: #09646d;
  color: white;
  padding: 10px 20px;
  margin: 0px 20px 20px 20px;
  border: none;
  border-radius: 10px;
}

/* addpackage */

.vendoraddpackage-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.vendoraddpackage-form-container {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
}

.vendoraddpackage-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.vendoraddpackage-form {
  display: flex;
  flex-direction: column;
}

.vendoraddpackage-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.vendoraddpackage-input-group {
  flex: 1;
  margin-right: 10px;
  font-size: 12px;
}

.vendoraddpackage-input-group2 {
  margin: 10px;
  font-size: 12px;
}

.vendoraddpackage-input-group:last-child {
  margin-right: 0;
}

.vendoraddpackage-select,
.vendoraddpackage-input,
.vendoraddpackage-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  font-size: 14px;
}

.vendoraddpackage-select:focus,
.vendoraddpackage-input:focus,
.vendoraddpackage-textarea:focus {
  border-color: #09646d;
}

.vendoraddpackage-checkbox-group {
  margin-bottom: 20px;
  font-size: 12px;
  flex: 1;
}

.vendoraddpackage-label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.vendoraddpackage-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.vendoraddpackage-checkbox input {
  margin-right: 10px;
}

.vendoraddpackage-services {
  width: 50%;
  margin-right: 10px;
  font-size: 12px;
}

.vendoraddpackage-service-list {
  border: 1px solid black;
  overflow-y: scroll;
  height: 80px;
  margin: 10px 10px 10px 0px;
  border-radius: 10px;
  padding: 0px 10px;
}

.vendoraddpackage-services:last-child {
  margin-right: 0;
}

.vendoraddpackage-service-list ul {
  padding: 0;
  list-style: none;
}

.vendoraddpackage-service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.vendoraddpackage-remove-button {
  background-color: #dc3545;
  border: none;
  color: #fff;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  height: 10px;
  width: 10px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.vendoraddpackage-remove-button:hover {
  background-color: #c82333;
}

.vendoraddpackage-input-wrapper {
  display: flex;
  align-items: center;
}

.vendoraddpackage-add-button {
  background-color: #09646d;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.vendoraddpackage-add-button:hover {
  background-color: #08565e;
}

.vendoraddpackage-submit {
  display: flex;
  justify-content: center;
}

.vendoraddpackage-button {
  background-color: #09646d;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vendoraddpackage-button:hover {
  background-color: #074a50;
}

.vendoraddpackageitinerary-container {
  padding: 20px;
  /* max-width: 600px; */
  margin: 0 auto 50px;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
}

.vendoraddpackageitinerary-header {
  display: flex;
  justify-content: space-between;
}

.vendoraddpackageitinerary-add-button {
  padding: 10px 20px;
  background-color: #09646d;
  color: white;
  border: none;
  border-radius: 10px;
  width: 275px;
}

.vendoraddpackageitinerary-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.vendoraddpackageitinerary-item {
  background-color: white;
  margin: 10px;
  width: 100%;
  border-radius: 10px;
}

.vendoraddpackageitinerary-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 20px;
  width: 100%;
}

.vendoraddpackageitinerary-card-content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.vendoraddpackageitinerary-photo {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px;
}

.vendoraddpackageitinerary-editbutton {
  padding: 10px 20px;
  background-color: #09646d;
  color: white;
  border: none;
  border-radius: 10px;
}
.vendoraddpackageitinerary-editbutton:hover {
  background-color: #074a50;
}

.vendoraddpackageitinerary-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendoraddpackageitinerary-modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  width: 300px;
}

.vendoraddpackageitinerary-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendoraddpackageitinerary-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.vendoraddpackageitinerary-input {
  width: 100%;
  padding: 7px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* .vendoraddpackageitinerary-photo-input { */
/* margin: 10px 0; */
/* } */

.vendoraddpackageitinerary-preview-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.vendoraddpackageitinerary-textarea {
  width: 100%;
  padding: 10px;
  /* margin: 10px 0; */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.vendoraddpackageitinerary-submit-button {
  height: 40px;
  width: 150px;
  background-color: #09646d;
  color: white;
  border: none;
  border-radius: 10px;
}

.vendoraddpackageitinerary-submit-container {
  display: flex;
  justify-content: center;
}

.price-range-container {
  position: relative;
  padding: 20px 0px;
}

.hotel-itinerary-img-carousel {
  height: 140px;
  border-radius: 10px;
}

.bid_update_button button {
  background-color: #09646d;
  border-radius: 10px;
  height: 50px;
  width: 150px;
  font-size: 18px;
  font-weight: 500;
}

.ckeditor-p-tag-mb0 p {
  margin-bottom: 0px;
}

.dashboard-overflow-x-auto {
  overflow-x: auto;
}

.dashboard-table {
  width: 100%;
  min-width: 100%;
  background-color: white;
  border: 1px solid #09646d;
  border-spacing: 0;
  border-collapse: collapse;
}

.dashboard-thead-tr {
  background-color: #09646d;
  border-radius: 0.375rem;
}

.dashboard-th {
  padding: 1rem;
  text-align: left;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.875rem;
}

.dashboard-tbody-tr {
  border-bottom: 1px solid #09646d;
}

.dashboard-td {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #09646d;
}

.dashboard-status-button {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 9999px;
  background-color: #4caf50; /* Default status button color */
}

/* Additional classes for different status colors */
.dashboard-status-active {
  background-color: #4caf50; /* Green */
}

.dashboard-status-inactive {
  background-color: #f44336; /* Red */
}

.dashboard-status-pending {
  background-color: #ff9800; /* Orange */
}

.dashboard-status-completed {
  background-color: #2196f3; /* Blue */
}

.dashboard-container {
  border: 1px solid #e5e7eb; /* Tailwind border color equivalent */
  border-radius: 1rem; /* rounded-xl */
  padding-top: 0.75rem; /* pt-3 */
  padding-bottom: 1rem; /* pb-4 */
  padding-left: 1.25rem; /* px-5 */
  padding-right: 1.25rem; /* px-5 */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; /* shadow-custom */
}

.dashboard-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem; /* mb-5 */
}

.dashboard-total-user {
  font-size: 0.875rem; /* text-sm */
  opacity: 0.6;
  font-weight: 600; /* font-semibold */
}

.dashboard-user-count {
  font-weight: 600; /* font-semibold */
  font-size: 1.25rem; /* text-xl */
  margin-top: 0.75rem; /* mt-3 */
}

.dashboard-percentage {
  font-size: 0.75rem; /* text-xs */
  opacity: 0.8;
  font-weight: 500; /* font-medium */
}

.dashboard-percentage-up {
  color: #22c55e; /* text-green-500 */
  font-weight: 500; /* font-medium */
}

.dashboard-container {
  border: 1px solid #e5e7eb; /* Tailwind border color equivalent */
  border-radius: 1rem; /* rounded-xl */
  padding-top: 0.75rem; /* pt-3 */
  padding-bottom: 1rem; /* pb-4 */
  padding-left: 1.25rem; /* ps-5 */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; /* shadow-sm and shadow-custom */
}

.dashboard-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem; /* mb-5 */
}

.dashboard-active-user {
  font-size: 0.75rem; /* text-xs */
  opacity: 0.6;
  font-weight: 600; /* font-semibold */
}

.dashboard-user-ratio {
  font-weight: 500; /* font-medium */
  opacity: 0.7;
  font-size: 0.875rem; /* text-sm */
  margin-top: 0.75rem; /* mt-3 */
}

.dashboard-user-count {
  font-size: 1.25rem; /* text-xl */
  font-weight: 700; /* font-bold */
  opacity: 1;
}

.dashboard-container h5 {
  font-weight: 600;
  font-size: 20px;
}

.margin-pricing-agency {
  padding: 10px;
}

.margin-pricing-agency-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1.4fr 1fr;
  gap: 10px;
  text-align: center;
}

.margin-pricing-agency h5 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(4, 71, 17);
  margin-bottom: 0px;
}

.margin-pricing-agency-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1.4fr 1fr;
  gap: 10px;
  text-align: center;
}

.margin-pricing-agency p {
  font-size: 16px;
  font-weight: 500;
}

.my-package-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.badge-label-sighseeing span {
  background-color: #074a50;
  color: white;
  border-radius: 5px;
  padding: 2px 10px 3px 10px;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.badge-success {
  background-color: #28a745;
}

.badge-danger {
  background-color: #dc3545;
}

.cancel-btn-vendor {
  border: none;
  font-size: 15px;
  font-weight: 600;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  background-color: #b8b8b8;
  color: black;
}

.submit-btn-vendor {
  border: none;
  font-size: 16px;
  font-weight: 600;
  width: 140px;
  height: 35px;
  border-radius: 5px;
  background-color: #08565e;
  color: white;
}

.custome-req-list-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.new-vendor-container {
  padding: 20px;
  margin-left: 165px;
  margin: 0px 30px 0px 170px;
  font-family: Arial, sans-serif;
}

/* .new-vendor-containers {
  margin: 0px 0px 0px 170px;
} */

.cutome-req-body-grid {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
}

.my-bid-object-margin-end {
  margin-right: 35px;
}

.bid-insert-body {
  border: 2px solid #09646d;
  border-radius: 10px;
}

.new-green-border {
  border: 2px solid #09646d;
}

.select-hotel-filter-box {
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  gap: 35px;
}

.select-hotel-filter-box input {
  width: 100%;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #09646d !important;
}

.select-hotel-filter-box select {
  width: 100%;
  padding: 8px 20px;
  border-radius: 5px;
  border: 1px solid #09646d !important;
}

.hotel-itinerary-state-city-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.booking-user-detail {
  display: flex;
  color: #09646d;
  text-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.booking-user-detail::after {
  content: " ";
  height: 1px;
  width: 100%;
  background-color: #09646d;
  display: inline-block;
  margin-top: 4px;
}

@media (max-width: 900px) {
  .my-package-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (max-width: 500px) {
  .custome-req-list-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .new-vendor-container {
    margin: 0px;
  }
  .my-package-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .my-bid-object-margin-end {
    margin-right: 0px;
  }
}

.vendor-common-input {
  width: 100%;
  padding: 8px 20px;
  border-radius: 5px;
  border: 1px solid #09646d !important;
}

.vendor-common-label {
  color: #09646d;
}

/* mb-2 font600 text14 */

.vendor-change-password-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.vendor-change-password-content-box {
  /* height: 80%; */
  width: 35%;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 40px 70px;
}

.position-relative {
  position: relative;
}

.eye-button {
  position: absolute;
  right: 10px;
  top: 50%;
  /* transform: translateY(-50%); */
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
}

.vendor-change-password-content-box div button img {
  height: 25px;
}

.no-reviews-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjust the height to position it in the center */
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  background-color: #f8f9fa;
}

.hotels-filter-input-css {
  width: 100%;
  padding: 5.5px 20px;
  border-radius: 5px;
  border: 1px solid #09646d !important;
}

.hotels-filter-label-css {
  color: #09646d;
}

.reset-button-css {
  color: #fff;
  background-color: #09646d;
  padding: 8px 30px;
  border-radius: 7px;
  font-size: 14px;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: rotate360 0.5s ease-in-out;
}