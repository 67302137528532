.booking-table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #09646d;
  border-radius: 30px;
  margin-top: 20px;
  /* border-style: hidden; */
}

.booking-table__th {
  color: #09646d;
  border: 2px solid #09646d;
  padding: 8px;
  font-size: 12px;
}

.booking-table__td {
  border: 1px solid #09646d;
  padding: 8px;
  font-size: 12px;
}

.booking-table__hotel-name {
  /* background-color: #f2f2f2; */
  color: #09646d;
  text-align: left;
  font-size: 15px;
}

.booking-table__hotel-id {
  /* background-color: #808080; */
  color: gray;
  text-align: left;
  font-size: 10px;
}

.booking-table__hotel-address {
  /* background-color: #1c1c1c; */
  color: #09646d;
  text-align: left;
  font-size: 12px;
}

.booking-table__no-hotel-details {
  font-size: 12px;
}

.booking-table__button {
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #09646d;
  color: white;
  border: none;
}

@media (max-width: 911px) {
  .booked_packega_margin {
    overflow-x: scroll;
  }
}

.filter-container {
  margin: 10px;
  border-bottom: 2px solid #09646d;
  padding-bottom: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.filter-title {
  margin: 0px 10px;
  font-size: 18px;
  font-weight: bolder;
}

.filter-container select {
  width: 140px;
  height: 40px;
  border-radius: 10px;
  color: #09646d;
  font-size: 15px;
  padding: 0px 10px;
  border: 2px solid #09646d;
}

/* view booked hotel */

.booked-details {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #09646d;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.booked-hotel-details-container {
  border-right: 1px solid black;
  width: 100%;
}

.booked-details-title {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  color: #09646d;
  margin-bottom: 20px;
}

.booked-details-name {
  font-size: 15px;
  font-weight: bold;
}

.booked-details-address {
  font-size: 13px;
}

.cust-details-headings {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  margin: 0px 10px;
}

.booked-user-details-container {
  border-right: 1px solid black;
  width: 100%;
  padding-left: 20px;
}

.booked-status-container {
  /* border:1px solid black; */
  padding-left: 20px;
  width: 100%;
}

.booked-status {
  background-color: #09646d;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px;
  text-align: center;
}

.update-status-title {
  font-size: 14px;
  color: #09646d;
}

.update-status-select {
  border-color: #09646d;
  border: 1px solid;
  text-align: center;
  font-size: 14px;
  color: #09646d;
  margin-right: 10px;
}

.update-status-button {
  font-size: 12px;
  border: 1px solid #09646d;
  background-color: #09646d;
  border-radius: 5px;
  padding: 3px 10px;
  color: white;
}

.update-status-button:hover {
  background-color: #074f55;
}

.booked-room-details {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  color: #09646d;
  margin-bottom: 20px;
}

.hotel-card-relative {
  position: relative;
}

.hotel-card-absolute {
  position: absolute;
  top: 0;
  left: 0;
  text-align: end;
}

.fixed-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #155e75;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fixed-button:hover {
  background-color: #0f3e4e;
}

.ckeditor-p-tag p{
  color: black;
  font-weight: normal;
}