.vendor_signup {
  border: 1px solid black;
  border-radius: 20px;
}

.sign_up_form {
  width: 70%;
  margin: auto;
}

.vendor_signup .sign_up_form {
  width: 100%;
  padding: 0 70px;
}

.sign_up_header {
  margin-bottom: 40px;
}

.sign_up_header h3 {
  color: #09646d;
  font-size: 30px;
}

.sign_up_header p {
  color: #09646d;
  font-size: 20px;
  font-weight: 500;
}

.sing_up_button {
  padding-top: 20px;
}

.sing_up_button button {
  background-color: #09646d;
  border-radius: 10px;
  height: 50px;
  font-size: 22px;
  font-weight: 500;
}

.vendor_signup input,
.vendor_signup select {
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  border: 1px solid black;
}

.vendor_signup input:focus,
.vendor_signup select:focus {
  outline: none;
}

.sing_up_button button:hover,
.sing_up_button button:active,
.sing_up_button button:focus {
  background-color: #09646d;
  border: none;
  box-shadow: none;
}

.form-control,
.form-select {
  border: 1px solid black !important;
}

.mama {
  margin-right: 50px !important;
  margin-left: 170px !important;
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
  .sign_up_header {
    margin-bottom: 20px;
  }
  .sign_up_form {
    width: 80%;
    margin: auto;
  }

  .vendor_signup .sign_up_form {
    width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 767.98px) {
  .sign_up_form {
    width: 70%;
    margin: auto;
  }
  .sign_up_header h3 {
    font-size: 25px;
  }
  .sign_up_header p {
    font-size: 18px;
  }
  .sing_up_button {
    padding-top: 35px;
  }
  .sing_up_button button {
    font-size: 18px;
    height: 40px;
  }
}

@media (max-width: 575.98px) {
  .sing_up_button {
    padding-top: 35px;
  }
}

.partial-payment-header p {
  text-align: center;
  background-color: #09646d;
  color: white;
  height: 14%;
  display: grid;
  place-items: center;
  margin: -2px;
  font-size: 24px;
  padding: 5px 0px;
}

.partial-payment-body {
  padding: 10px 120px 10px 20px;
}

.partial-payment-body div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partial-payment-body h5 {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 15px;
}

.partial-payment-body p {
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 15px;
}

.partial-payment-button{
  padding: 0px 25px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5px;
}
.partial-payment-button select{
  font-size: 14px;
  border-radius: 5px;
}

/* .partial-payment-button button {
  text-align: center;
  background-color: #09646d;
  color: white;
  display: grid;
  place-items: center;
  font-size: 14px;
  padding: 2px 0px;
  width: 100%;
  border-radius: 7px;
} */

.travellers-table{
  display: grid;
  grid-template-columns: 0.2fr 1.8fr 1fr 1fr;
  /* border: 1px solid #b8b8b8; */
  border-radius: 10px;
}

.travellers-table span{
  font-size: 12px;
  color: gray;
}

.travellers-table p{
  font-size: 16px;
  font-weight: 600;
}


.partial-payment-table-header-payment{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.partial-payment-body-table{
  padding: 5px 20px;
}

.partial-payment-table-header-payment span {
  font-size: 12px;
  color: gray;
  font-weight: 600;
}

.partial-payment-table-header-payment p {
  font-size: 14px;
  font-weight: 600;
}