.review-card{
    display:flex;
    justify-content: space-between;
}

.view-review-button{
    font-size: 12px;
    padding:5px 10px;
    background-color: #09646D;
    color:white;
    border:none ;
    border-radius: 5px;
    cursor: pointer;
    /* margin:5px 30px; */
}

.view-review-button:hover{
    background-color: #08545b;
}