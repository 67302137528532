/* .CuStom_package_image img {
  height: 100%;
} */

.CuStom_package {
  border: 2px solid #b8b8b8;
  height: 370px;
  border-radius: 10px 10px 10px 10px;
}

.vendor_CuStom_package {
  height: auto !important;
  padding: 20px 10px;
}

.details_box {
  height: auto;
}

.included_service {
  margin: 0 auto !important;
}

.book_now_box {
  padding: 20px !important;
  height: auto;
}

.inner_CuStom_package {
  border: 2px solid #b8b8b8;
  border-radius: 10px 10px 10px 10px;
}

.booking_details_card {
  width: 100%;
  max-width: 680px;
  margin-left: 20px;
}

.cancel_btn {
  background: transparent;
  padding: 10px 15px;
  border: 1px solid #2a8387;
  color: #2a8387;
  border-radius: 5px;
  text-transform: capitalize;
}

.CuStom_package_header {
  text-align: center;
  background-color: #09646d;
  color: white;
  height: 14%;
  display: grid;
  place-items: center;
  border-radius: 10px 10px 0px 0px;
  margin: -2px;
  font-size: 24px;
}

.overview-packages {
  flex-wrap: wrap;
}

.CuStom_package_content {
  padding: 10px 20px;
}

.CuStom_package_content p {
  font-size: 18px;
}

.CuStom_package_content span {
  font-size: 18px;
  font-weight: 600;
}

.CuStom_package_icon {
  color: #ffd600;
}

.CuStom_package_images {
  display: flex;
  gap: 10px;
}

.custom-package-row {
  margin: 0 auto !important;
}

.book_now_box .CuStom_package_images {
  flex-wrap: wrap;
}

.details_box .CuStom_package_images {
  flex-wrap: wrap;
}

.CuStom_package_images p {
  font-weight: 300;
  font-size: 11.1135px;
  text-align: center;
}

.CuStom_package_btn {
  width: 100%;
  background-color: #ff630c;
  border-radius: 10px;
  border: none;
  height: 35px;
  font-weight: 400;
  font-size: 16px;
  color: white;
}

.CuStom_package_btn_2 {
  background-color: #09646d;
}

.amh_to_hima {
  padding: 20px 30px 30px 30px;
}

.amh_to_hima_border {
  border: 1px solid #b8b8b8;
  border-radius: 10px 0px 0px 10px;
}

.amh_to_hima_border_2 {
  border-radius: 0px 10px 10px 0px;
}

/*--------------------- amh_to_hima ----------------------*/

.amh_to_hima {
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

.cmn_2 {
  padding: 28px 40px;
}

.package_service {
  padding: 10px 24px;
}

.cmn__3 {
  padding: 30px 30px;
}

.cmn__3 h6 {
  font-weight: 600;
}

.cmnp {
  font-weight: 500;
}

/*------------------------------ my_package_content --------------------------------------*/

.my_package_content {
  padding: 0px 20px;
}

.my_package_content .category_title {
  font-weight: 600;
  margin-bottom: 0;
}
.my_package_content .category_desc {
  margin-bottom: 0;
}

.my_package_content .category_desc span {
  font-weight: 400;
}

.my_package_content p,
.my_package_content span,
.my_package_content button {
  font-size: 16px;
}

.my_package_content a {
  text-decoration: none;
  font-size: 14px;
  color: #ff4c04;
}

.my_package_content a:hover {
  color: #ff4c04;
}

@media (max-width: 1199.98px) {
  .CuStom_package_content p {
    margin-bottom: 6px !important;
  }

  .my_package_content p {
    margin-bottom: 3px !important;
  }

  .CuStom_package_btn {
    height: 35px;
    width: 80%;
  }

  .my_package_content {
    padding: 12px 20px;
  }

  .cmn_2 {
    padding: 20px;
  }
}

@media (max-width: 991.98px) {
  .CuStom_package_header {
    font-size: 20px;
    border-radius: 0px;
  }

  .CuStom_package_content p {
    font-size: 14px;
  }
  .CuStom_package_images p {
    font-weight: 300;
    font-size: 8px;
  }
  .CuStom_package_content span {
    font-size: 16px;
    font-weight: 600;
  }
  .CuStom_package_btn {
    font-size: 14px;
  }
  .CuStom_package_images img {
    width: 80%;
  }
  .CuStom_package_btn {
    height: 25px;
    width: 80%;
  }
  .my_package_content {
    padding: 6px 20px;
  }
  .my_package_btn {
    height: 25px;
    width: 100%;
    font-size: 12px;
  }

  .book_now_box .inner_CuStom_package {
    width: 100%;
  }

  .posi-first {
    order: 2;
  }

  .posi-second {
    order: 1;
    margin-bottom: 20px !important;
    padding: 0 !important;
  }

  .posi-third {
    order: 3;
  }

  .booking_details_card {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 767.98px) {
  .CuStom_package {
    border: 2px solid #b8b8b8;
    margin-top: 10px;
    padding-bottom: 30px;
    border-radius: 10px;
  }
  .CuStom_package_content {
    padding: 10px 20px;
  }

  .CuStom_package_content p {
    font-size: 20px;
  }

  .CuStom_package_content span {
    font-size: 20px;
    font-weight: 600;
  }

  .CuStom_package_icon {
    color: #ffd600;
  }

  .CuStom_package_images {
    display: flex;
  }

  .CuStom_package_images p {
    font-weight: 300;
    font-size: 11.1135px;
    text-align: center;
  }

  .CuStom_package_btn {
    width: 100%;
    background-color: #ff630c;
    border-radius: 10px;
    border: none;
    height: 44px;
    font-weight: 400;
    font-size: 18px;
    color: white;
  }

  .CuStom_package_btn_2 {
    background-color: #09646d;
  }
}

.meadia-for-package-details-img {
  height: 370px;
}

.hover-buttons-media {
  display: flex;
  flex-wrap: wrap;
}

.booked-package-list-media {
  color: #09646d;
  font-weight: 700;
  font-size: 46px;
}

.booked-package-list-image {
  height: 350px;
  border-radius: 10px;
}

@media (max-width: 575.98px) {
  .booking-detail-right-box{
    padding: 10px 5px;
  }
  .CuStom_package {
    height: auto;
    min-height: 260px;
    margin-bottom: 10px;
  }
  .meadia-for-package-details-img {
    height: 150px;
  }
  .hover-buttons-media {
    display: flex;
  }
  .booked-package-list-media {
    color: #09646d;
    font-weight: 600;
    font-size: 30px;
  }
  .booked-package-list-image {
    height: 150px;
    border-radius: 10px;
  }
  .CuStom_package_header {
    text-align: center;
    background-color: #09646d;
    color: white;
    height: 11%;
    display: grid;
    place-items: center;
    border-radius: 0px 0px 4px 4px;
    margin-top: -15px;
    font-size: 16px;
  }
  .CuStom_package_content p {
    font-size: 12px;
  }
  .CuStom_package_content span {
    font-size: 10px;
    font-weight: 600;
  }
  .button-9090 {
    line-height: 100%;
    padding: 5px 4px;
    width: 140px;
  }
  .my_package_content p,
  .my_package_content span,
  .my_package_content button {
    font-size: 12px;
  }
  .CuStom_package_content {
    border: none;
  }

  .CuStom_package_content {
    padding: 10px;
  }

  .amh_to_hima {
    padding: 20px 30px 30px 20px;
    margin-bottom: 15px;
  }
  .cmntext {
    font-size: 16px;
    font-weight: 500;
  }
  .media-for-booked-data-table {
    font-size: 14px;
  }
  .over-btn {
    background-color: #09646d;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    width: 100%;
    height: 40px;
    font-weight: 500;
    font-size: 20px;
  }
  .media-border-none {
    border: none;
  }
  .media-CuStom_package_header {
    border-radius: 10px 10px 0px 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.reject-modal {
  background-color: #09646d;
  border-radius: 10px;
  border: none;
  height: 34px;
  font-weight: 400;
  font-size: 16px;
  color: white;
  padding: 0px 30px;
}

.reject-modal-no {
  background-color: #b8b8b8;
  color: black;
}

.booking-detail-right-box{
  padding: 10px 20px;
}
