/* display hotels */

.hotels-container {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.hotel-card {
  display: flex;
  border: 1px solid black;
  border-radius: 10px;
  width: 310px;
  height: 160px;
  padding: 0px 5px;
  position: relative;
}

.hotel-card-image-container {
  padding: 5px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

.hotel-card-image {
  height: 120px;
  width: 120px;
  border-radius: 10px;
}

.hotel-card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hotel-card-ratingandtitle {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid gray;
  margin-top: 5px;
  margin-bottom: 10px;
}

.hotel-card-name {
  font-weight: 700;
  font-size: 15px;
  width: 120px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.hotel-card-rating-star {
  color: gold;
}

.hotel-card-address {
  font-size: 10px;
  margin: 5px 0px;
}

.hotel-card-descripiton {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  max-height: 23px;
}

.hotel-card-button {
  font-size: 12px;
  padding: 2px 5px;
  background-color: #09646d;
  border-radius: 5px;
  border: 0px;
  color: white;
  cursor: pointer;
  /* position:absolute; */
  /* bottom:5px; */
  /* right:5px; */
  width: 100%;
  margin-bottom: 10px;
}

.hotel-card-button:hover {
  background-color: #074f56;
}

@media (max-width: 991px) {
  .hotels-container {
    justify-content: center;
  }
}

/*  */

.hotel-model-footer {
  text-align: end;
  background-color: white;
}

.hotel-model-title-container {
  display: flex;
  justify-content: space-between;
}

.cross-icon {
  font-size: 20px;
}

.hotel-model-footer {
  padding: 10px;
}

.plus-icon-container {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.hotel-model-footer-button {
  padding: 8px 30px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #09646d;
  color: #ddd;
  border: none;
}

.edit-icon {
  border: 2px solid #09646d;
  border-radius: 50%;
  color: #09646d;
  padding: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.cancel-edit-button {
  background-color: gray;
  color: black;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 10px;
  border: none;
  margin-left: 10px;
}

/* hotelreview */

.hotel-review-container {
  display: flex;
  padding: 0px 70px;
  justify-content: end;
}

.hotel-review-wrapper {
  align-self: flex-end;
  width: 200px;
}

.review-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

/* add hotels */
.hotel_section_margin {
  margin-left: 220px;
  margin-right: 50px;
  padding: 25px 60px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid lightgray;
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .hotel_section_margin {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.hotel-details-container {
  padding: 20px;
}

.form-section {
  padding: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group-container {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 0;
  gap: 3px;
}

@media (max-width: 768px) {
  .form-group-container {
    display: flex;
    gap: 10px;
    /* flex:1; */
    flex-direction: column;
  }

  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* background-color: #F5F5F5; */
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.other-form-group {
  display: flex;
}

.other-add-button {
  background-color: #09646d;
  color: white;
  border-radius: 50%;
  padding: 10px;
}

.plus-icon {
  border: 2px solid #09646d;
  border-radius: 50%;
  color: #09646d;
  padding: 5px;
  cursor: pointer;
}

.minus-icon {
  border: 1px solid #09646d;
  border-radius: 50%;
  color: #09646d;
  padding: 3px;
  cursor: pointer;
  font-size: 13px;
}

.input-and-minus {
  display: flex;
  align-items: center;
}

.input-and-minus input {
  flex: 1;
  margin-right: 10px;
}

/* highlights section */

.highlights-title-container {
  display: flex;
  justify-content: space-between;
}

.highlights-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.highlight-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlights-icon {
  width: 50px;
  border-radius: 10px;
  height: 50px;
}

.highlights-title {
  font-size: 13px;
  color: rgb(99, 99, 99);
}

.highlight-model {
  background-color: white;
}

.highlight-model-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
  margin-top: 30px;
  height: 300px;
  overflow: scroll;
}

.highlight-model-section::-webkit-scrollbar {
  display: none;
}

.highlight-model-image {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}

.highlight-model-form-check {
  width: fit-content;
  height: 100px;
  display: flex;
  align-items: flex-start;
}

.highlight-model-single {
  text-align: center;
  width: 80px;
  /* height: 120px; */
  overflow-y: hidden;
}

.highlight-model-highlight-title {
  font-size: 12px;
}

/* photos-section */

.hotel-pictures-title-container {
  display: flex;
  justify-content: space-between;
}

.hotel-pictures-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.hotel-pictures-item {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  position: relative;
}

.hotel-image {
  width: 120px;
  height: 120px;
  border-radius: 20px;
}

.trash-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: red;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid red;
  padding: 4px;
  margin: 2px;
}

.submit-button-section {
  margin-left: 180px;
  margin-right: 14px;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: end;
}

.submit-button {
  padding: 9px 60px;
  border-radius: 10px;
  border: 1px solid lightgray;
  background-color: #09646d;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.submit-button:hover {
  background-color: #085258;
}

@media (max-width: 768px) {
  .submit-button {
    padding: 10px 50px;
    margin-right: 25px;
  }

  .submit-button-section {
    margin-left: 14px;
    margin-right: 14px;
  }

  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

/* aminities and facilities */

.af-title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 10px;
  padding: 10px;
  /* margin: 0px 80px */
}

.grid-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.grid-item-title {
  display: flex;
  justify-content: space-between;
}

.grid-item h3 {
  margin-top: 0;
}

.grid-item ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 10px;
}

.grid-item ul li::before {
  content: "• ";
  color: #09646d;
}

@media (max-width: 768px) {
  .grid-container {
    margin: auto;
  }
}

.edit2-icon {
  color: #09646d;
  padding: 0px 2px;
  /* margin-left: 10px; */
  cursor: pointer;
  font-size: 15px;
}

.delete-icon {
  cursor: pointer;
  color: red;
  font-size: 14px;
  border-radius: 50%;
  padding: 0px 2px;
}

.af-model-input1 {
  font-size: 14px;
  padding: 8px 8px;
  /* margin: 5px 10px 5px 5px; */
  width: 100%;
  border-radius: 5px;
  border: 1px solid #09646d;
}

.af-model-subpoint {
  display: flex;
}

.af-model-subpoint-container {
  width: 100%;
}

.af-model-input2 {
  font-size: 14px;
  padding: 8px 8px;
  margin: 5px 10px 5px 5px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #09646d;
}

/* Location */

.location-form {
  display: flex;
  margin: 20px;
  justify-content: space-between;
}

.location-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f5f5f5;
  font-size: 14px;
  width: 90%;
}

.location-button {
  width: 18%;
  padding: 8px 15px;
  border-radius: 10px;
  border: 1px solid lightgray;
  background-color: #09646d;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 15px;
}

.map-container {
  height: 400px;
  width: 80%;
  margin: auto;
  border: 1px solid black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* add rooms */

.rooms-title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.rooms-card-section {
  display: flex;
  flex-wrap: wrap;
}

.room-card {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 16px;
  width: fit-content;
  align-items: center;
  padding: 10px 20px 10px 10px;
}

.room-image {
  width: 120px;
  height: 100px;
  object-fit: cover;
  border-radius: 7px;
}

.room-details {
  margin-left: 10px;

  width: 160px;
}

.room-details ul {
  list-style-type: none;
  padding: 0;
}

.room_title {
  font-size: 10px;
  font-weight: 800;
}

.room-highlight-container {
  overflow: scroll;
  height: 80px;
}

.room-highlight-container::-webkit-scrollbar {
  display: none;
}

.room-highlight {
  font-size: 10px;
  color: rgb(102, 102, 102);
}

.room-price {
  margin: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #09646d;
}

.room-per-night {
  font-size: 10px;
  color: rgb(102, 102, 102);
  font-weight: lighter;
}

.room-model-title {
  font-size: 14px;
  padding: 8px 8px;
  /* margin: 5px 10px 5px 5px; */
  width: 100%;
  border-radius: 5px;
  border: 1px solid #09646d;
}

.room-model-subpoint {
  display: flex;
}

.room-model-subpoint-container {
  width: 100%;
}

.room-model-input2 {
  font-size: 14px;
  padding: 8px 8px;
  margin: 5px 10px 5px 5px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #09646d;
}

.room-model-image-section {
  display: flex;
}

.room-model-add-images-title-container {
  display: flex;
  justify-content: space-between;
}

.room-model-image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 10px 0px;
}

.room-model-image-item {
  position: relative;
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.room-model-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.room-model-add-image-button {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #09646d;
  color: #ddd;
  border: none;
}

.room-model-input3 {
  font-size: 14px;
  padding: 8px 8px;
  /* margin: 5px 10px 5px 5px; */
  width: 100%;
  border-radius: 5px;
  border: 1px solid #09646d;
}

/* property policies container */

.pp-title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.policy-title {
  font-size: 16px;
  margin-bottom: 10px;
}

.policy-description {
  font-size: 14px;
}

.policy-age-container {
  display: flex;
  gap: 10px;
  margin: 20px 0px;
}

.policy-card {
  width: 400px;
  border: 1px solid gray;
  height: 200px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 10px;
}

.policy-card::-webkit-scrollbar {
  display: none;
}

.policy-card-info {
  font-size: 15px;
  margin-bottom: 8px;
}

.policy-card-point {
  font-size: 12px;
  margin: 1px;
}

.policy-others {
  font-size: 15px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.policy-other-point {
  font-size: 12px;
}

/* review */

.review-container {
  max-height: 650px;
  overflow-y: scroll;
}

/* .review-card {
  border-bottom: 1px solid #09646d;
} */

.review-box {
  /* margin-top: 20px; */
}

.reviews_list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
