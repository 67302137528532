.book-hotel-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1212121;
}

.book-hotel-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 700px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.book-hotel-modal-content h2 {
  font-weight: 600;
  padding-bottom: 12px;
  border-bottom: 2px solid black;
  margin-bottom: 20px;
}

.book-hotel-modal-content label {
  font-weight: 500;
  margin-bottom: 5px;
}

.book-hotel-modal-content input,
.book-hotel-modal-content select {
  width: 100%;
  padding: 4px 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px;
  border: 2px solid rgb(171, 174, 178);
}

.booking-modal-buttons-1 {
  text-decoration: none;
  background: #09646d;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  padding: 12px 40px;
}

.booking-modal-buttons-2 {
  text-decoration: none;
  background: #09646d;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  padding: 12px 40px;
}

.counter {
  display: flex;
  align-items: center;
}

.counter button {
  background: #09646d;
  color: #fff;
  border: none;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.counter button:disabled {
  background: #ccc;
}

.counter span {
  margin: 0 10px;
  font-size: 16px;
}

.close-button {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.close-button:hover {
  background: #c82333;
}
